import {
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import Evaluation, {
  EvaluationMode,
} from "../../../../domain/entities/evaluation";
import { ResourceEvaluationState } from "../../../../domain/entities/resourceEvaluationState.enum";
import { SupplierEvaluationState } from "../../../../domain/entities/supplierEvaluationState.enum";
import { formatDateBasedOnLanguage } from "../../../../utils";
import {
  Filter,
  Sort,
  UpdateFilter,
  UpdateSort,
} from "../../../hooks/Site/useSiteResourceDetailViewModel";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";

type ResourceEvaluationHistoryModalProps = {
  onClose: () => void;
  evaluations: Evaluation<ResourceEvaluationState | SupplierEvaluationState>[];
  getEvaluations?: () => void;
  getEvaluationsIsLoading?: boolean;
  evaluationsHasNextPage?: boolean;
  evaluationsFetchNextPage?: () => void;
  filterResourceEvaluations?: Filter;
  updateFilterResourceEvaluations?: UpdateFilter;
  sortResourceEvaluations?: Sort;
  updateSortResourceEvaluations?: UpdateSort;
};

const ResourceEvaluationHistoryModal = ({
  onClose,
  evaluations,
  getEvaluations,
  getEvaluationsIsLoading,
  evaluationsHasNextPage,
  evaluationsFetchNextPage,
  sortResourceEvaluations,
  updateSortResourceEvaluations,
  updateFilterResourceEvaluations,
  filterResourceEvaluations,
}: ResourceEvaluationHistoryModalProps) => {
  
  const { t } = useTranslation("siteResources");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const portalRef = useRef();
  const filterColumnTypes = {
    createdAt: "date-range",
    mode: "select",
    result: "select",
    evaluator: "text",
  };

  const selectOptions = {
    mode: Object.values(EvaluationMode).map((mode) => ({
      option: mode,
      label: t(`evaluationMode.${mode}`, { ns: "enum" }),
    })),
    result: Object.values(ResourceEvaluationState).map((state) => ({
      option: state,
      label: t(`resourceEvaluation.${state}`, { ns: "enum" }),
    })),
  };

  const rows = ["createdAt", "mode", "result", "evaluator"];

  useEffect(() => {
    getEvaluations();
  }, []);

  return (
    <Modal isOpen={true} onClose={() => onClose()} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent
        style={{ background: "white", padding: "2rem" }}
        overflowY={"auto"}
        ref={portalRef}
      >
        <ModalHeader padding={0} marginBottom={4}>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            alignItems="center"
            textAlign="center"
            flexDirection="row"
          >
            <Text textColor={"black"} fontSize={18}>
              {t("evaluationsHistory", { ns: "documents" })}
            </Text>
            <Flex flex={1} />
            <IconButton
              marginLeft={3}
              fontSize="3xl"
              size="2xl"
              backgroundColor="transparent"
              onClick={onClose}
              aria-label="Edit"
            >
              <MdClose />
            </IconButton>
          </Flex>
        </ModalHeader>
        <Flex
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={isMobile ? "calc(100vw - 70px)" : "100%"}
          marginTop={5}
          position="relative"
          overflow="auto"
          id="table-container-modal"
        >
          <InfiniteTable
            autosize
            bottomThreshold={800}
            tableId="resource-evaluation-history-modal-evaluations-table"
            infiniteScroll={{
              dataLength: evaluations.length,
              hasNextPage: evaluationsHasNextPage,
              fetchNextPage: evaluationsFetchNextPage,
            }}
            emptyText={t("noEvaluations", { ns: "documents" })}
            isLoading={getEvaluationsIsLoading}
          >
            <Thead>
              <Tr>
                {rows?.map((row) => (
                  <Th width={isMobile ? 160 : 120} key={row}>
                    <TableColumnHeader
                      text={t(row)}
                      filter={{
                        component: (
                          <ColumnFilterComponent
                            selectOptions={selectOptions[row]}
                            type={filterColumnTypes[row]}
                            value={filterResourceEvaluations[row]}
                            updateFilter={(value) =>
                              updateFilterResourceEvaluations(row, value)
                            }
                          />
                        ),
                        isActive: !!(Array.isArray(
                          filterResourceEvaluations[row]
                        )
                          ? filterResourceEvaluations[row][1]
                          : filterResourceEvaluations[row]),
                      }}
                      sort={{
                        handler: (direction) =>
                          updateSortResourceEvaluations(row, direction),
                        direction: sortResourceEvaluations[row],
                      }}
                      isInModal
                    />
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody borderRadius="xl">
              {evaluations?.map((evaluation, i) => (
                <Tr key={i}>
                  <Td width={100}>
                    {formatDateBasedOnLanguage(evaluation.createdAt, true)}
                  </Td>
                  <Td width={100}>
                    {t(`evaluation.mode.${evaluation.mode}`, { ns: "common" })}
                  </Td>
                  <Td width={100}>
                    {t(`evaluation.resource.${evaluation.result}`, {
                      ns: "common",
                    })}
                  </Td>
                  <Td width={100}>{evaluation.evaluator ?? "-"}</Td>
                </Tr>
              ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ResourceEvaluationHistoryModal;
