import { useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { useAuth } from "../../providers/Auth0JWTProvider";
import TagType from "../../../domain/entities/tag";
import Tag from "../../../domain/entities/tag";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import DocumentType from "../../../domain/entities/documentType";
import { useLocation } from "react-router-dom";

export interface AddDocumentTypeViewModel {
  getTags(companyId: string, search?: string): Promise<Tag[]>;
  getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
    resourceId?: string,
    systemOnly?: boolean,
    category?: DocumentTypeCategory,
  ): Promise<DocumentType[]>;
}


export type UseAddDocumentType = {
  hookDocumentCategory: DocumentTypeCategory,
  setHookDocumentCategory: (category: DocumentTypeCategory) => void,
  documentTypes: DocumentType[],
  documentTypesHasNextPage: boolean,
  documentTypesFetchNextPage,
  documentTypesIsFetching: boolean,
  getDocumentTypes: () => void,
  tags: string[],
  setTags: (tags: string[]) => void,
  search: string,
  setSearch: (search: string) => void,
  sort: SortMeta,
  setSort: (sort: SortMeta) => void,
  allTags: TagType[],
  getAllTags: () => void,
  setEnableGetTags: (value: boolean)=>void,
  systemDocTypes: DocumentType[],
  getSystemDocTypes: () => void,
  systemDocumentTypesHasNextPage: boolean,
  systemDocumentTypesFetchNextPage: () => void,
  systemDocumentTypesFetching: boolean,
  setGetSystemDocTypes: (getSystemDocs: boolean) => void,
  setSupplierId?: (supplierId: string) => void,
};

function getResourceId(url: string) {
  const regex = /\/resources\/([^/]+)\/([^/]+)/;
  const match = url.match(regex);
  if (match && match?.length > 2)
    return {
      resourceId: match[2],
    };
  return null;
}

function useAddDocumentType(
  viewModel: AddDocumentTypeViewModel,
  category: DocumentTypeCategory,
): UseAddDocumentType {

  const { companyId } = useAuth();
  const location = useLocation();
  const resourceInfo = getResourceId(location.pathname);
  
  const currentCategory = category?.includes('supplier') ?
    category === DocumentTypeCategory.SUPPLIER_SITE ?
      DocumentTypeCategory.SITE :
      DocumentTypeCategory.COMPANY :
    category;
  const [search, setSearch] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [sort, setSort] = useState<SortMeta>();
  const [supplierId, setSupplierId] = useState<string>("");
  const [hookDocumentCategory, setHookDocumentCategory] = useState<DocumentTypeCategory>(currentCategory);
  const [hasFetchedOnce, setHasFetchedOnce] = useState<boolean>(false); // 1. Add state to track if fetched once
  const [getSystemDocTypes, setGetSystemDocTypes] = useState<boolean>(false);
  const [enableGetTags, setEnableGetTags] = useState<boolean>(false);

  const documentTypesQuery = useInfiniteQuery(
    [
      "documentTypes",
      companyId,
      search,
      tags,
      sort,
      getSystemDocTypes,
      hookDocumentCategory
    ],
    async ({ pageParam = 1 }) => {
      const resourceId = resourceInfo?.resourceId ?? supplierId;
      const systemDocTypes = getSystemDocTypes ?? null;
      const data = await viewModel.getDocumentTypes(
        companyId,
        search,
        tags,
        sort,
        pageParam,
        resourceId,
        systemDocTypes,
        hookDocumentCategory
      );
      if (data && !hasFetchedOnce) {
        setHasFetchedOnce(true);
      }
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
        return undefined;
      },
      retry: 0,
    },
  );

  

  const tagsQuery = useQuery<TagType[], Error>(
    ["tags", companyId],
    async () => { 
      return await viewModel.getTags(companyId)
    },
    { 
      initialData: [],
      enabled: enableGetTags
     },
    
  );

  const systemDocTypes = documentTypesQuery.data?.pages?.flat() ?? [];
  const documentTypes = documentTypesQuery.data?.pages?.flat() ?? [];

  return {
    hookDocumentCategory,
    setHookDocumentCategory,
    documentTypes,
    documentTypesHasNextPage: documentTypesQuery.hasNextPage,
    documentTypesFetchNextPage: documentTypesQuery.fetchNextPage,
    documentTypesIsFetching: documentTypesQuery.isLoading,
    getDocumentTypes: documentTypesQuery.refetch,
    tags,
    setTags,
    search,
    setSearch,
    sort,
    setSort,
    getAllTags: tagsQuery.refetch,
    setEnableGetTags,
    allTags: tagsQuery.data,
    systemDocTypes,
    getSystemDocTypes: documentTypesQuery.refetch,

    systemDocumentTypesHasNextPage: documentTypesQuery.hasNextPage,
    systemDocumentTypesFetchNextPage: documentTypesQuery.fetchNextPage,
    systemDocumentTypesFetching: documentTypesQuery.isLoading,
    setGetSystemDocTypes,
    setSupplierId,
  };
}

export default useAddDocumentType;
