import React, { FC, useCallback, useEffect, useState } from "react";
import { SmartDownloadSubContextDataType } from "../../../hooks/SmartDownload/useSmartDownloadViewModel";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Tbody, Td, Th, Thead, Tr, useMediaQuery } from "@chakra-ui/react";
import SimpleTable from "../table/SimpleTable";
import TableColumnHeader from "../table/TableColumnHeader";

interface SmartDownloadSelectCompanySubContextProps {
  setSubContextData: (value: SmartDownloadSubContextDataType) => void;
}

const SmartDownloadSelectCompanySubContext: FC<SmartDownloadSelectCompanySubContextProps> = ({ setSubContextData }) => {
  const { t } = useTranslation("common");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const isSelected = useCallback((id: string) => selectedOptions.includes(id), [selectedOptions]);

  const toggleItem = useCallback((id: string) => {
    setSelectedOptions((prev) => {
      const newSelection = prev.includes(id) ? prev.filter((s) => s !== id) : [...prev, id];
      setSubContextData(newSelection.length === 0 ? null : (newSelection[newSelection.length - 1] as SmartDownloadSubContextDataType));
      return newSelection;
    });
  }, [setSubContextData]);

  const toggleSelectAll = useCallback((selectAll: boolean) => {
    const allOptions = ['documentType.company', 'documentType.site', 'documentType.all'];
    setSelectedOptions(selectAll ? allOptions : []);
    setSubContextData(selectAll ? 'documentType.all' : null);
  }, [setSubContextData]);

  useEffect(() => {
    const allOptionsSelected = selectedOptions.includes('documentType.company') && selectedOptions.includes('documentType.site');
    if (allOptionsSelected && !isSelected('documentType.all')) {
      setSelectedOptions((prev) => [...prev, 'documentType.all']);
    } else if (!allOptionsSelected && isSelected('documentType.all')) {
      setSelectedOptions((prev) => prev.filter((s) => s !== 'documentType.all'));
    }
  }, [selectedOptions, isSelected]);

  const CheckboxRow: FC<{ id: string; label: string }> = useCallback(({ id, label }) => (
    <Tr width="100%">
      <Td width={10} onClick={(e) => e.stopPropagation()}>
        <Checkbox
          borderColor="gray.500"
          isChecked={isSelected(id)}
          onChange={() => toggleItem(id)}
        />
      </Td>
      <Td>{t(label)}</Td>
    </Tr>
  ), [isSelected, toggleItem, t]);

  return (
    <Box
      id="table-container-modal"
      border="1px solid"
      borderRadius="10px"
      borderColor="gray.300"
      width={isMobile ? "calc(100vw - 120px)" : "100%"}
      overflow="auto"
    >
      <SimpleTable autosize tableId="company-sub-context-table">
        <Thead>
          <Tr>
            <Th width={10}>
              <Checkbox
                borderColor="gray.500"
                isChecked={isSelected('documentType.all')}
                onChange={() => toggleSelectAll(!isSelected('documentType.all'))}
              />
            </Th>
            <Th width={200}>
              <TableColumnHeader
                text={t('name')}
                isInModal
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <CheckboxRow id="documentType.company" label="smartDownload.documentsCompany" />
          <CheckboxRow id="documentType.site" label="smartDownload.documentsSite" />
        </Tbody>
      </SimpleTable>
    </Box>
  );
};

export default SmartDownloadSelectCompanySubContext;
