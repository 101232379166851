import { Flex, Tbody, Td, Th, Thead, Tooltip, Tr, useMediaQuery, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import DocumentPreset from "../../../../domain/entities/documentPreset";
import Tag from "../../../../domain/entities/tag";
import Typology from "../../../../domain/entities/typology";
import Variant from "../../../../domain/entities/variant";
import LabelFilter from "../../../components/Common/select/LabelFilter";
import CellTagList from "../../../components/Common/table/CellTagList";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import DnDTable from "../../../components/Common/table/DnDTable";
import RenderIf from "../../../components/Permissions/RenderIf";
import { Permission } from "../../../components/Permissions/Permissions";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

type Props = {
  rows: Array<DocumentPreset>;
  presetsAreLoading: boolean;
  onSelect?: (row: DocumentPreset) => void;
  onReorder: (presets: DocumentPreset[]) => void;
  typologies: Array<Typology>;
  typologiesFilter: Array<Typology>;
  renameTypologyError: string;
  onDelete?: (documentPreset: DocumentPreset) => void;
  onFilterByTypology?: (typologies: Array<Typology>) => void;
  onAddTypology?: (documentPreset: DocumentPreset, typology: Tag) => void;
  onDeleteTypology?: (
    documentPreset: DocumentPreset,
    typology: Typology,
  ) => void;
  onUpdate?: (tag: Tag) => void;

  setShowDeleteAlert?: (value: boolean) => void;
  setAskConfirmDelete?: (value: boolean) => void;
  setTypologyToRemove?: (typology: Variant) => void;
  setDocuPresetToUnlink?: (documentPreset: DocumentPreset) => void;
  isTagLoading?: boolean;
  autosize?: boolean;
};

const DocumentPresetsTable = ({
  rows,
  presetsAreLoading,
  onSelect,
  onReorder,
  typologies,
  onDelete,
  onUpdate,
  onAddTypology,
  onDeleteTypology,
  onFilterByTypology,
  typologiesFilter,
  setShowDeleteAlert,
  setAskConfirmDelete,
  setTypologyToRemove,
  setDocuPresetToUnlink,
  renameTypologyError,
  isTagLoading,
  autosize,
}: Props) => {
  const { t } = useTranslation("documentsPresets");
  const toast = useToast();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const element = rows.find((el) => el.id === result.draggableId);

    if (element.order === result.destination.index + 1) {
      return;
    }

    const newArray = [...rows];
    const index = rows.findIndex((obj) => obj.id === result.draggableId);
    const movedObject = newArray.splice(index, 1)[0];
    newArray.splice(result.destination.index, 0, movedObject);

    await onReorder(newArray);
  };

  useEffect(() => {
    if (renameTypologyError)
      toast({
        title: t(renameTypologyError, { ns: "common" }),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
  }, [renameTypologyError]);

const colsWidth = [
    "80px",
    "300px",
    "300px",
    "90px",
  ];

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      width="100%"
      overflow={"auto"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      marginTop={4}
    >
      <DnDTable
        autosize={autosize}
        dnd={{
          onDragEnd,
          droppableId: "document-presets",
        }}
        showEmptyText={!rows || rows.length === 0}
        emptyText={t("noPreset")}
        isLoading={presetsAreLoading}
      >
        <Thead>
          <Tr>
            <Th textColor="gray.700" width={colsWidth[0]} />
            <Th textColor="gray.700" width={isMobile ? "200px" : colsWidth[1]}>{t("name")}</Th>
            <Th verticalAlign="top" textColor="gray.700" width={isMobile ? "200px" : colsWidth[2]}>
              <LabelFilter
                options={typologies}
                items={typologiesFilter}
                id="filter-typologies"
                onChange={onFilterByTypology}
                margin="0px 8px 0px 0px"
              />
              {t("typologies")}
            </Th>
            <Th width={colsWidth[3]} />
          </Tr>
        </Thead>
        <Tbody>
          {rows?.map((row) => (
            <Tr key={row.id} id={row.id} onClick={() => onSelect?.(row)}>
              <Tooltip
                label={row?.documentType?.description}
                key={row?.id}
                placement="bottom-start"
                openDelay={500}
              >
                <Td textColor={"gray.700"} width={isTablet ? "200px" : colsWidth[1]}>
                  {row.documentType.name}
                </Td>
              </Tooltip>
              <Td width={isTablet ? "200px" : colsWidth[2]} textColor={"gray.700"}>
                <RenderIf
                  permissions={Permission.Company_EditPreset}
                  fallback={
                    <CellTagList
                      tags={typologies}
                      selectedTags={
                        Array.isArray(row?.typologies) ? row.typologies : []
                      }
                      editable={true}
                      isTagLoading={isTagLoading}
                    />
                  }
                >
                  <CellTagList
                    tags={typologies}
                    selectedTags={
                      Array.isArray(row?.typologies) ? row.typologies : []
                    }
                    onAdd={(name) => onAddTypology?.(row, name)}
                    onDelete={(typology) => {
                      if (row.typologies.length === 1) {
                        setDocuPresetToUnlink(row);
                        setTypologyToRemove(typology as Variant);
                        setShowDeleteAlert(true);
                      } else {
                        onDeleteTypology?.(row, typology as Typology);
                      }
                    }}
                    onUpdate={onUpdate}
                    editable={true}
                    isTagLoading={isTagLoading}
                  />
                </RenderIf>
              </Td>
              <RenderIf permissions={[Permission.Company_DeletePreset]}  check={PermissionCheck.All}>
                <Td
                  width={colsWidth[3]}
                  style={{ textAlign: "right", cursor: "pointer" }}
                >
                  <DeleteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete?.(row), setAskConfirmDelete(true);
                    }}
                  />
                </Td>
              </RenderIf>
            </Tr>
          ))}
        </Tbody>
      </DnDTable>
    </Flex>
  );
};

export default DocumentPresetsTable;
