import {
  Box,
  Flex,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import ContentLayout from "../../layout/ContentLayout";
import { useSupplierListModel } from "../../hooks/Suppliers/useSupplierListModel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import { GetGlobalSupplierFilters } from "../../../domain/repositories/supplierRepository";
import { COLORS } from "../../assets/theme/colors";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { Alert } from "../Common/Alert";
import React, { useState } from "react";
import { SupplierStatus } from "../../../domain/entities/supplierStatus.enum";
import SearchInput from "../Common/SearchInput";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { FiPlus } from "react-icons/fi";
import CreateDerivedTenantModal from "./CreateDerivedTenantModal";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import { Permission } from "../../components/Permissions/Permissions";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

const SupplierListView = () => {
  const { t } = useTranslation("supplier");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const {
    suppliers,
    suppliersRefetch,
    suppliersHasNextPage,
    suppliersFetchNextPage,
    isFetching,
    filterSuppliers,
    updateFilterSuppliers,
    setSort,
    sort,
    setSearch,
  } = useSupplierListModel();
  const navigate = useNavigate();
  const columns: {
    field: keyof GetGlobalSupplierFilters;
    type: "text" | "select" | "date-range";
  }[] = [
    { field: "name", type: "text" },
    { field: "vatCode", type: "text" },
    { field: "address", type: "text" },
  ];
  const excludeSort = new Set(["address"]);
  const fSuppliers = filterSuppliers ?? {
    name: "",
    protocol: "",
    location: "",
  };
  const [showNavigationAlert, setShowNavigationAlert] =
    useState<boolean>(false);
  const [showCreationDerivedTenants, setShowCreationDerivedTenants] =
    useState<boolean>(false);

  const canViewSupplierDetails = useHasPermissions([
    Permission.Supplier_ShowDetails,
  ]);
  const canViewSupplierResources = useHasPermissions(
    [
      Permission.Supplier_ShowWorkers,
      Permission.Supplier_ShowChemicals,
      Permission.Supplier_ShowVehicles,
      Permission.Supplier_ShowTools,
      Permission.Supplier_ShowMachines,
    ],
    PermissionCheck.Some,
  );

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf
            permissions={Permission.Supplier_CreateDerivedTenants ?? []}
            check={PermissionCheck.All}
          >
            <ActionBarItem
              description={t("createDerivedTenant", { ns: "supplier" })}
              icon={FiPlus}
              onClick={() => setShowCreationDerivedTenants(true)}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        p={10}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        <Flex
          width="calc(100vw - 200px)"
          flexDirection={isTablet ? "column" : "row"}
          justifyContent="space-between"
          gap={4}
          alignItems={isTablet ? "start" : "center"}
        >
          <Box textAlign={"start"}>
            <Text
              textColor={COLORS.sikuroBlue}
              fontSize={20}
              fontWeight={"bold"}
            >
              {t("listOfSuppliers")}
            </Text>
            <Text fontSize={16} color="black" fontWeight={"normal"}>
              {t("listSupplierDescription")}
            </Text>
          </Box>
          <SearchInput onSearch={setSearch} />
        </Flex>

        <Flex
          marginTop={5}
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width="calc(100vw - 210px)"
          position="relative"
          overflow="auto"
          id="table-container"
        >
          <InfiniteTable
            autosize={true}
            tableId="company-suppliers-table"
            infiniteScroll={{
              dataLength: suppliers?.length,
              hasNextPage: suppliersHasNextPage,
              fetchNextPage: suppliersFetchNextPage,
            }}
            isLoading={isFetching}
            emptyText={t("noSuppliers", { ns: "supplier" })}
            showEmptyText={suppliers?.length === 0}
          >
            <Thead>
              <Tr bg={"gray.300"}>
                {columns.map((c) => (
                  <Th width={200} key={c.field}>
                    <TableColumnHeader
                      text={t(`${c.field}`)}
                      filter={
                        excludeSort.has(c.field)
                          ? undefined
                          : {
                              isActive:
                                !!fSuppliers[c.field] &&
                                (!Array.isArray(fSuppliers[c.field]) ||
                                  !!fSuppliers[c.field][0]),
                              component: (
                                <ColumnFilterComponent
                                  value={fSuppliers[c.field]}
                                  type={c.type}
                                  updateFilter={(value) =>
                                    updateFilterSuppliers(c.field, value)
                                  }
                                  namespace="enum"
                                />
                              ),
                            }
                      }
                      sort={{
                        handler: (direction) =>
                          setSort({ field: c.field, direction }),
                        direction:
                          sort?.field === c.field ? sort.direction : null,
                      }}
                    />
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(suppliers) &&
                suppliers?.map((supplier, index) => (
                  <Tr
                    key={index}
                    onClick={() =>
                      supplier.status === SupplierStatus.PENDING
                        ? setShowNavigationAlert(true)
                        : canViewSupplierDetails
                          ? navigate(`/suppliers/${supplier.id}`)
                          : canViewSupplierResources
                            ? navigate(`/suppliers/${supplier.id}/resources`)
                            : ""
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <Td width={200}>{supplier?.name}</Td>
                    <Td width={200}>
                      <Flex flexDirection="row" alignItems="center">
                        {supplier?.vat}
                        {supplier.derivedFromCompanyId && (
                          <Tag
                            size={"md"}
                            key={supplier?.vat}
                            variant="outline"
                            colorScheme="gray"
                            marginLeft={2}
                          >
                            <TagLabel>{t("derived")}</TagLabel>
                          </Tag>
                        )}
                      </Flex>
                    </Td>
                    <Td width={200}>
                      {supplier.street ? (
                        <>
                          {supplier.street && (
                            <span>
                              {supplier.street}
                              {(supplier.city || supplier.cap) && ` - `}
                            </span>
                          )}
                          {supplier.city && (
                            <span>
                              {supplier.city}
                              {supplier.cap && ` - `}
                            </span>
                          )}
                          {supplier.cap && <span>{supplier.cap}</span>}
                        </>
                      ) : (
                        "-"
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </InfiniteTable>
        </Flex>

        {showNavigationAlert && (
          <Alert
            title={t("warning", { ns: "common" })}
            message={t("navigationBlocked", { ns: "supplier" })}
            variant="info"
            onClose={() => {
              setShowNavigationAlert(false);
            }}
          />
        )}
        {showCreationDerivedTenants && (
          <CreateDerivedTenantModal
            onCancel={() => setShowCreationDerivedTenants(false)}
            onConfirm={() => {
              suppliersRefetch();
              setShowCreationDerivedTenants(false);
            }}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default SupplierListView;
