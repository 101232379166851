import {
  Box,
  Checkbox,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useMediaQuery
} from "@chakra-ui/react";
import { BlobProvider } from "@react-pdf/renderer";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { ImSpinner6 } from "react-icons/im";
import { MdCloudDownload } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useApp } from "../../../app";
import Requirement from "../../../domain/entities/requirement";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import Site, { SiteState } from "../../../domain/entities/site";
import Staff, { StaffType } from "../../../domain/entities/staff";
import { COLORS } from "../../assets/theme/colors";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DataBox from "../../components/Common/DataBox";
import RequirementsPdf from "../../components/Common/RequirementsPdf";
import StatusToggle from "../../components/Common/StatusToggle";
import FormDateField from "../../components/Common/forms/FormDateField";
import FormTextField from "../../components/Common/forms/FormTextField";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../components/Permissions/RenderIf";
import StaffTableView from "../../components/Views/Company/StaffTableView";
import CreateUpdateStaffModal from "../../components/Views/Staff/CreateStaffModal";
import { ResourceDocumentsPermissions } from "../../components/Views/common/SiteResourceDocuments";
import useSiteDetailsViewModel from "../../hooks/Site/useSiteDetailsViewModel";
import useSiteRequirementsViewModel from "../../hooks/Site/useSiteRequirementsViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import LoadingView from "../Common/LoadingView";
import CompanyDetailActionBar from "../Company/CompanyDetailActionBar";
import SiteTopBarName from "./SiteTopBarName";
import WorkingSiteDocumentsPanel from "./WorkingSiteDocumentsPanel";

type SiteDetailsPermissions = {
  documents: ResourceDocumentsPermissions;
  addDocument: Permission;
  downloadDocuments: Permission;
  showGeneralDocs?: Permission;
  showStaff: Permission;
  editStaff: Permission;

  };

const SiteDetailsView: FC<{
  readonly: boolean;
  showStatus: boolean;
  permissions: SiteDetailsPermissions;
  isWorkingSite: boolean;
}> = (props) => {
  const { siteId } = useParams();
  const siteHook = useSiteDetailsViewModel(siteId);

  return siteHook.loading ?
    <LoadingView /> :
    <SiteDetailsViewMain {...props} siteHook={siteHook} />;
}

const SiteDetailsViewMain: FC<{
  readonly: boolean;
  showStatus: boolean;
  permissions: SiteDetailsPermissions;
  isWorkingSite: boolean;
  siteHook: ReturnType<typeof useSiteDetailsViewModel>
}> = ({ readonly, showStatus, permissions, isWorkingSite, siteHook }) => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const { updateContext, context } = useApp();
  const [isEditing, setIsEditing] = useState(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [resourceType, setResourceType] = useState<RequirementSubject>();
  const [disableDownloadPdf, setDisableDownloadPdf] = useState<boolean>(true);
  const canViewStaff = useHasPermissions([permissions.showStaff], PermissionCheck.All);
  const canViewGeneralDocs = useHasPermissions([permissions.showGeneralDocs], PermissionCheck.All);


  const {
    site,
    isFetching,
    updateSite,
    updateSiteImage,
    updateState,
    staff,
    staffIsFetching,
    updateStaffIsLoading,
    createStaffIsLoading,
    createStaff,
    updateFilter,
    staffFilter,
    staffSort,
    setStaffSort,
    deleteStaff,
    updateStaff,
    deleteStaffIsLoading,
    coordinates,
    customFields
  } = siteHook;

  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [requirementsByResource, setRequirementsByResource] = useState<
    Record<string, Requirement[]>
  >({});
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const reqs = useSiteRequirementsViewModel(
    (resourceType as RequirementSubject) || RequirementSubject.SITE
  );
  const { requirements, requirementsLoading } = reqs(
    siteId,
    resourceType as RequirementSubject
  );
  
  const [checkedItems, setCheckedItems] = useState({
    worker: false,
    chemical: false,
    vehicle: false,
    tool: false,
    site: false,
    company: false,
  });

  const handleSelection = (
    e: ChangeEvent<HTMLInputElement>,
    value: RequirementSubject
  ) => {
    setCheckedItems((prev) => ({
      ...prev,
      [value]: e.target.checked,
    }));
    if (e.target.checked && value) {
      setResourceType(value);
    } else if (
      Object.keys(requirementsByResource).includes(value) &&
      !e.target.checked
    ) {
      delete requirementsByResource[value];
    }
  };

  useEffect(() => {
    if (
      resourceType &&
      !Object.keys(requirementsByResource).includes(resourceType) &&
      requirements?.length > 0
    ) {
      setRequirementsByResource((prevState) => {
        return {
          ...prevState,
          [resourceType]: requirements,
        };
      });
    }
  }, [requirements]);

  useEffect(() => {
    if (isOpen) {
      setCheckedItems({
        worker: false,
        chemical: false,
        vehicle: false,
        tool: false,
        site: false,
        company: false,
      });
    }
  }, [isOpen]);

  const menuItems = [
    {
      label: t("sub.workers", { ns: "navbar" }),
      key: "worker",
    },
    {
      label: t("sub.vehicles", { ns: "navbar" }),
      key: "vehicle",
    },
    {
      label: t("sub.machines", { ns: "navbar" }),
      key: "machine",
    },
    {
      label: t("sub.tools", { ns: "navbar" }),
      key: "tool",
    },
    {
      label: t("sub.chemicals", { ns: "navbar" }),
      key: "chemical",
    },
    {
      label: t("sub.suppliers", { ns: "navbar" }),
      key: "supplier",
    }
  ];
  let results: Requirement[] = [];
  for (const [_, value] of Object.entries(requirementsByResource)) {
    results = [...results, ...value];
  }

  const [confirmChangeStateModal, setConfirmChangeStateModal] = useState<SiteState | false>(false);

  context?.site?.options &&
    localStorage.setItem(
      "isReviewEnabled",
      String(context?.site?.options?.isEvaluationApprovalRequired)
    );

  const onSubmit: SubmitHandler<Site> = (data) => {
    if (uploadedImageFile) {
      updateSiteImage(
        data,
        uploadedImageFile
      );
    } else {
      updateSite(data);
    }
    setIsEditing(false);
  };

  const resetSite = () => {
    reset(site);
  };

  const toggleIsEditing = (isEditing: boolean) => {
    if (!isEditing) {
      setUploadedImageFile(undefined);
      resetSite();
    }

    setIsEditing(isEditing);
  };

  const methods = useForm<Site>({
    mode: "all",
    defaultValues: site,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    updateContext({
      topBarTitle: <SiteTopBarName site={site} stateVisible={true} />,
    });
  }, [site, updateContext]);

  useEffect(() => {
    checkedItems && setDisableDownloadPdf(Object.values(checkedItems).some(value => value === true))
  }, [checkedItems]);

  const createStaffAction = async (staffElement: Staff) => {
    await createStaff(staffElement);
    setShowCreate(false);
  };
  const link = `https://www.google.com/maps/place/${coordinates?.lat},${coordinates?.lng}`

  const canSetStatus = useHasPermissions([
    Permission.Sites_Show,
    Permission.Sites_EditState,
  ], PermissionCheck.All);

  const requiredRule = {
    required: t("requiredField", { ns: "common" }),
  };
  return (
    <ContentLayout
      action={
        <>
          {!readonly ? (
            <Flex flexDirection={"column"} alignItems={"center"}>
              <CompanyDetailActionBar
                onAdd={() => setShowCreate(true)}
                isEditing={isEditing}
                canSave={isEditing && isValid}
                onSave={handleSubmit(onSubmit)}
                onToggleEdit={isFetching ? undefined : toggleIsEditing}
                editDetailsPermissions={[
                  Permission.Sites_EditDetails,
                ]}
                editStaffPermissions={[
                  Permission.Sites_EditStaff,
                ]}
              />
            </Flex>
          ) : null}
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Popover isOpen={isOpen}>
              <PopoverTrigger>
                <ActionBarItem
                  icon={MdCloudDownload}
                  description={t("pdf.tooltipExportPdf", {
                    ns: "common",
                  })}
                  onClick={() => {
                    setIsOpen(true)
                    setRequirementsByResource({})
                  }}
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton
                    cursor={"pointer"}
                    onClick={() => setIsOpen(false)}
                  />
                  <PopoverBody>
                    <Flex flexDirection={"column"} gap={2} my={4}>
                      {menuItems.map((menuItem) => {
                        return (
                          <Checkbox
                            isChecked={checkedItems[menuItem.key]}
                            isDisabled={requirementsLoading}
                            key={menuItem.key}
                            onChange={(e) => handleSelection(e, menuItem.key as RequirementSubject)}
                          >
                            {menuItem.label}
                          </Checkbox>
                        );
                      })}
                    </Flex>
                  </PopoverBody>

                  <PopoverFooter display={"flex"} justifyContent={"center"}>
                    <Tooltip
                      shouldWrapChildren
                      label={disableDownloadPdf ? t("pdf.tooltipExportPdf", {
                        ns: "common",
                      }): t("pdf.selectResourceType", {
                        ns: "common",
                      }) }
                    >
                      <BlobProvider
                        document={
                          <RequirementsPdf
                            data={results}
                            columnsVariantTable={[
                              t("pdf.variants", {
                                ns: "common",
                              }),
                              t("pdf.documents", {
                                ns: "common",
                              }),
                            ]}
                            columnsSpecializationTable={[
                              t("pdf.specializations", {
                                ns: "common",
                              }),
                              t("pdf.documents", {
                                ns: "common",
                              }),
                            ]}
                            title={t("pdf.tooltipExportPdf", {
                              ns: "common",
                            })}
                            subTitle={t("pdf.subTitle", {
                              ns: "common",
                            })}
                          />
                        }
                      >
                        {({ url, loading }) => (
                          <Box
                            backgroundColor={disableDownloadPdf ? COLORS.sikuroBlue : COLORS.gray}
                            width={"18rem"}
                            color={COLORS.white}
                            padding={2}
                            borderRadius={"5px"}
                          >
                            <Link
                              onClick={(e) => {
                                setIsOpen(false);
                                setRequirementsByResource({})
                              }}
                              href={disableDownloadPdf ? url : null}
                              _hover={{
                                textDecoration: "none",
                                cursor: disableDownloadPdf ? "pointer"  : "not-allowed"
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Flex
                                gap={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                {!requirementsLoading ? (
                                  <>
                                    {t("pdf.download", {
                                      ns: "common",
                                    })}
                                    {loading ? (
                                      <ImSpinner6 />
                                    ) : (
                                      <FaCloudDownloadAlt />
                                    )}
                                  </>
                                ) : (
                                  <Spinner />
                                )}
                              </Flex>
                            </Link>
                          </Box>
                        )}
                      </BlobProvider>
                    </Tooltip>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        </>
      }
    >
      {site && (
        <FormProvider {...methods}>
          <Flex
            padding={10}
            gap="20px"
            flexWrap={isTablet ? "wrap" : "nowrap"}
            width={"100%"}
          >
            <Box
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              width={isTablet || !showStatus ? "100%" : "calc(100vw - 600px)"}
            >
              <DataBox
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : site.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                title={t("summary")}
                isEditing={isEditing}
                isLoading={isFetching}
                fields={[
                  <FormTextField
                    key="protocol"
                    name="protocol"
                    label={t("protocol")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="description"
                    name="description"
                    label={t("description")}
                  />,
                  <FormTextField
                    key="contract"
                    name="contract"
                    label={t("contract")}
                  />,
                  <FormTextField
                    key="stakeholder"
                    name="stakeholder"
                    label={t("stakeholder")}
                  />,
                  <FormDateField
                    key="startDate"
                    name="startDate"
                    label={t("startDate")}
                  />,
                  <FormTextField
                    key="lifespan"
                    name="lifespan"
                    label={t("lifespan")}
                    type="number"
                  />,
                  <FormTextField
                    key="address"
                    name="address"
                    label={t("address")}
                    link={link}
                  />,
                  <FormTextField key="city" name="city" label={t("city")} />,
                  <FormTextField
                    key="zipCode"
                    name="zipCode"
                    label={t("zipCode")}
                  />,
                ]}
                customFields={customFields.data}
              />
            </Box>

            {showStatus && (
              <Flex
                p="10px 20px"
                flexDirection="column"
                gap="10px"
                width="300px"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                flex="1 1 300px"
              >
                <Box textStyle="h2">{t("state")}</Box>
                <StatusToggle
                  status={site.state}
                  onChange={(status) =>
                    setConfirmChangeStateModal(status as SiteState)
                  }
                  type="site"
                  disabled={!canSetStatus}
                  isTablet={isTablet}
                  isMobile={isMobile}
                />
              </Flex>
            )}
          </Flex>

          <RenderIf
            permissions={[permissions.showStaff, permissions.showGeneralDocs]}
            check={PermissionCheck.Some}
          >
            <Tabs
              px={10}
            >
              <TabList>
                {canViewGeneralDocs && readonly  && <Tab>{t("siteGeneralDocuments")}</Tab>}
                {canViewStaff && <Tab>{t("siteFigures")}</Tab>}
              </TabList>
              <TabPanels>
                {canViewGeneralDocs && readonly && (
                  <TabPanel px={0}>
                    <WorkingSiteDocumentsPanel
                      siteId={siteId}
                      permission={permissions.documents}
                    />
                  </TabPanel>
                )}
                {canViewStaff && <TabPanel px={0}>
                  <StaffTableView
                    staffIsLoading={updateStaffIsLoading}
                    deleteStaffIsLoading={deleteStaffIsLoading}
                    staffType={StaffType.SITE_CUSTOM}
                    staffs={staff}
                    isFetching={staffIsFetching}
                    sorting={staffSort}
                    filters={staffFilter}
                    updateStaffsSorting={setStaffSort}
                    updateStaffsFilter={updateFilter}
                    updateStaffElement={updateStaff}
                    deleteStaffElement={deleteStaff}
                    canEditPermissions={[
                      permissions.editStaff
                    ]}
                    from={"site"}
                    deltaWith={264}
                    tableId={StaffType.SITE_CUSTOM}
                  />
                </TabPanel>}
              </TabPanels>
            </Tabs>
          </RenderIf>
        </FormProvider>
      )}

      {showCreate && (
        <CreateUpdateStaffModal
          createUpdateStaffIsLoading={
            createStaffIsLoading || updateStaffIsLoading
          }
          onCancel={() => {
            setShowCreate(false);
          }}
          onConfirm={createStaffAction}
          staffType={StaffType.SITE_CUSTOM}
          title={t("createStaff", {
            ns: "staff",
          })}
        />
      )}

      {confirmChangeStateModal && (
        <ConfirmAlert
          onCancel={() => {
            setConfirmChangeStateModal(false);
          }}
          onConfirm={async () => {
            updateState(confirmChangeStateModal as SiteState);
            setConfirmChangeStateModal(false);
          }}
          title={t("updateMessage")}
          variant="warning"
        />
      )}
    </ContentLayout>
  );
};

export default SiteDetailsView;
