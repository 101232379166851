import Evaluation, {
  EvaluationMode,
} from "../../../../domain/entities/evaluation";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import {
  Sort,
  UpdateFilter,
  UpdateSort,
} from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { useTranslation } from "react-i18next";
import { Flex, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { formatDateBasedOnLanguage } from "../../../../utils";
import React from "react";
import { GetDocumentEvaluationFilter } from "../../../../domain/repositories/documentRepository";
import SimpleTable from "../../Common/table/SimpleTable";
import { useMediaQuery } from "@chakra-ui/react";

type DocumentEvaluationsTableProps = {
  documentEvaluations: Evaluation<ResourceDocumentEvaluationState>[];
  updateFilterDocumentEvaluations: UpdateFilter;
  filterDocumentEvaluations: GetDocumentEvaluationFilter;
  sortDocumentEvaluations: Sort;
  updateSortDocumentEvaluations: UpdateSort;
  autosize?: boolean;
  documentEvaluationsFetching?: boolean;
  canSeeExpiration?: boolean;
};

const DocumentEvaluationsTable = ({
  documentEvaluations,
  filterDocumentEvaluations,
  sortDocumentEvaluations,
  updateSortDocumentEvaluations,
  updateFilterDocumentEvaluations,
  autosize,
  documentEvaluationsFetching,
  canSeeExpiration
}: DocumentEvaluationsTableProps) => {
  const { t } = useTranslation("documents");
  const evaluationColumns = [
    "createdAt",
    "mode",
    "result",
    "evaluator",
  ];
  canSeeExpiration &&  evaluationColumns.push("expiresAt");

  const selectOptions = {
    mode: Object.values(EvaluationMode).map((mode) => ({
      option: mode,
      label: t(`evaluationMode.${mode}`, { ns: "enum" }),
    })),
    result: Object.values(ResourceDocumentEvaluationState).map((state) => ({
      option: state,
      label: t(`evaluation.document.${state}`, { ns: "common" }),
    })),
  };
  const filterColumnTypes = {
    createdAt: "date-range",
    mode: "select",
    result: "select",
    evaluator: "text",
  };

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      width={isMobile ? "900px" : "100%"}
      position="relative"
      overflow={"hidden"}
      marginTop={4}
      minHeight={"50vh"}
      id="table-container-modal"
    >
      <SimpleTable
        autosize={autosize}
        tableId="document-evaluation-table"
        emptyText={t("noEvaluations")}
        showEmptyText={documentEvaluations.length === 0}
        isLoading={documentEvaluationsFetching}
      >
        <Thead>
          <Tr>
            {evaluationColumns.map((key) => (
              <Th key={key}>
                <TableColumnHeader
                  text={t(key, { ns: "siteResources" })}
                  filter={{
                    isActive: !!(Array.isArray(filterDocumentEvaluations[key])
                      ? filterDocumentEvaluations[key][1]
                      : filterDocumentEvaluations[key]),
                    component: (
                      <ColumnFilterComponent
                        selectOptions={
                          selectOptions[key]
                            ? [...selectOptions[key]]
                            : undefined
                        }
                        type={filterColumnTypes[key]}
                        value={filterDocumentEvaluations[key]}
                        updateFilter={(value) =>
                          updateFilterDocumentEvaluations(key, value)
                        }
                      />
                    ),
                  }}
                  sort={{
                    handler: (direction) =>
                      updateSortDocumentEvaluations(key, direction),
                    direction: sortDocumentEvaluations[key],
                  }}
                  isInModal
                />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {documentEvaluations?.map((evaluation, i) => (
            <Tr key={i}>
              <Td>{formatDateBasedOnLanguage(evaluation.createdAt, true)}</Td>
              <Td>{t(evaluation.mode)}</Td>
              <Td>
                {t("evaluation.document." + evaluation.result, {
                  ns: "common",
                })}
              </Td>
              <Td>{evaluation.evaluator ?? "-"}</Td>
              {canSeeExpiration && <Td>{formatDateBasedOnLanguage(evaluation.expiresAt) ?? "-"}</Td>}
            </Tr>
          ))}
        </Tbody>
      </SimpleTable>
    </Flex>
  );
};

export default DocumentEvaluationsTable;
