import {
  Box,
  Button,
  Checkbox,
  Flex,
  Select,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BadgeResourceType } from "../../../../domain/entities/badgeResourceType.enum";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Machine from "../../../../domain/entities/machine";
import { SiteMachine } from "../../../../domain/entities/siteMachine";
import { SiteVehicle } from "../../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../../domain/entities/siteWorker";
import Vehicle from "../../../../domain/entities/vehicle";
import Worker from "../../../../domain/entities/worker";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";
import { Alert } from "../../../screens/Common/Alert";
import LoadingView from "../../../screens/Common/LoadingView";
import BaseModal from "../../Common/alerts/BaseModal";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import TextInput from "../../Common/TextInput";

type LinkBadgesToSiteResourcesModalProps = {
  resources: (Worker | Machine | Vehicle)[];
  isLoadingResources: boolean;
  resourcesHasNextPage: boolean;
  resourcesFetchNextPage: () => void;
  resourcesFilters: { [p: string]: any };
  resourcesSort: SortMeta;
  updateResourcesFilter: (
    field: keyof GetResourcesFilter,
    value: string | string[],
  ) => void;
  setResourcesSort: (sort: SortMeta) => void;
  onConfirm: (resource: any) => void;
  onClose: () => void;
  resourceType: BadgeResourceType;
  setResourceType: (resourceType: BadgeResourceType) => void;
  search?: string;
  setSearch?: (search: string) => void;
  error?: string;
  setError?: (error: string) => void;
  isLinking?: boolean;
};
type TableColumn = {
  field: keyof GetResourcesFilter;
  type: "text" | "tags";
  width: string;
  label: string;
};

type TableColumns = {
  [key in BadgeResourceType]: TableColumn[];
};

const LinkBadgesToSiteResourcesModal = ({
  resources,
  isLoadingResources,
  resourcesHasNextPage,
  resourcesFetchNextPage,
  resourcesFilters,
  resourcesSort,
  updateResourcesFilter,
  setResourcesSort,
  onConfirm,
  onClose,
  resourceType,
  isLinking,
  setResourceType,
  search,
  setSearch,
  error,
  setError,
}: LinkBadgesToSiteResourcesModalProps) => {
  const { t } = useTranslation("badges");
  const [selectedResource, setSelectedResource] = useState<
    SiteWorker | SiteMachine | SiteVehicle
    >();
  
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const tableColumns: TableColumns = {
    [BadgeResourceType.WORKER]: [
      { field: "lastName", type: "text", label: t("lastName"), width: "100px" },
      { field: "firstName", type: "text", label: t("firstName"), width: "100px" },
      { field: "fiscalCode", type: "text", label: t("fiscalCode"), width: "230px" },
      { field: "jobTitle", type: "text", label: t("jobTitle"), width: "100px" },
    ],
    [BadgeResourceType.MACHINE]: [
      { field: "name", type: "text", label: t("name"), width: "100px" },
      { field: "plate", type: "text", label: t("plate"), width: "100px" },
      { field: "model", type: "text", label: t("model"), width: "100px" },
    ],
    [BadgeResourceType.VEHICLE]: [
      { field: "name", type: "text", label: t("name"), width: "100px" },
      { field: "plate", type: "text", label: t("plate"), width: "100px" },
      { field: "model", type: "text", label: t("model"), width: "100px" },
    ],
  };
  const toggleSelectedResource = (resource) => {
    if (selectedResource && selectedResource.id === resource.id) {
      setSelectedResource(null);
    } else {
      setSelectedResource(resource);
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      footer={[
        <Button
          key="confirm"
          isDisabled={!selectedResource}
          colorScheme="blue"
          onClick={() => onConfirm(selectedResource)}
          isLoading={isLinking}
        >
          {t("confirm", { ns: "common" })}
        </Button>,
        <Button key="cancel" colorScheme="red" onClick={onClose}>
          {t("cancel", { ns: "common" })}
        </Button>,
      ]}
    >
      <Text fontSize="xl" fontWeight={500} py="4">
        {t("linkResource")}
      </Text>
      <Select
        value={resourceType}
        onChange={(e) => setResourceType(e.target.value as BadgeResourceType)}
      >
        <option value={null}>{t("linkResourceSubtitle")}</option>
        {Object.values(BadgeResourceType).map((opt) => (
          <option key={opt} value={opt}>
            {t(opt)}
          </option>
        ))}
      </Select>
      <Box pt="4">
        <TextInput
          placeholder={t("search", { ns: "common" })}
          value={search}
          onChange={(value) => setSearch(value)}
        />
      </Box>
      {!isLoadingResources && resourceType && resources.length > 0 && (
        <Box pt="4">
          <Flex
            flexDirection="column"
            alignItems="start"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width={isMobile ? "calc(100vw - 120px)" : "100%"}
            overflow="auto"
            marginTop={3}
            id="table-container-modal"
          >
            <InfiniteTable
              autosize={true}
              isLoading={isLoadingResources}
              bottomThreshold={220}
              infiniteScroll={{
                dataLength: resources.length,
                hasNextPage: resourcesHasNextPage,
                fetchNextPage: resourcesFetchNextPage,
              }}
              tableId="select-resources-table"
            >
              <Thead>
                <Tr>
                  <Th width={10}></Th>
                  {tableColumns[resourceType].map((column) => (
                    <Th width={column.width} key={column.field}>
                      <TableColumnHeader
                        text={t(`columns.${column.field}`, { ns: "badges" })}
                        filter={{
                          component: (
                            <ColumnFilterComponent
                              type="text"
                              value={resourceType[column.field]}
                              updateFilter={(value) =>
                                updateResourcesFilter(
                                  column.field,
                                  value as string | string[],
                                )
                              }
                              namespace="badges"
                            />
                          ),
                          isActive: !!resourcesFilters[column.field],
                        }}
                        sort={{
                          handler: (direction) =>
                            setResourcesSort({
                              field: column.field,
                              direction,
                            }),
                          direction:
                            resourcesSort &&
                            resourcesSort.field === column.field
                              ? resourcesSort.direction
                              : null,
                        }}
                        isInModal
                      />
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {resources.map((resource) => (
                  <Tr key={resource.id}>
                    <Td width={10}>
                      <Checkbox
                        borderColor={"gray.500"}
                        isChecked={
                          selectedResource &&
                          selectedResource.id === resource.id
                        }
                        onChange={() => toggleSelectedResource(resource)}
                      ></Checkbox>
                    </Td>
                    {tableColumns[resourceType].map((column) => (
                      <Td width={column.width} key={column.field}>{resource[column.field]}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </InfiniteTable>
          </Flex>
        </Box>
      )}
      {isLoadingResources && (
        <Box py="16">
          <LoadingView />
        </Box>
      )}

      {error && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(error, { ns: "errors" })}
          onClose={() => {
            setSelectedResource(null);
            setError(null);
          }}
          variant="warning"
        />
      )}
    </BaseModal>
  );
};

export default LinkBadgesToSiteResourcesModal;
