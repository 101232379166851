import { useTranslation } from "react-i18next";
import { formatDateBasedOnLanguage } from "../../../../utils";
import InfiniteTable from "../../Common/table/InfiniteTable";
import BadgeReaderStatus from "../../Common/BadgeReaderStatus";
import BadgeReader from "../../../../domain/entities/badgeReader";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { CheckCircleIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import BadgeReaderDirection from "../../../components/Common/BadgeReaderDirection";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { BadgeReaderState } from "../../../../domain/entities/badgeReaderState.enum";
import { Flex, Icon, Tbody, Td, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { BadgeReadersFilters } from "../../../../domain/repositories/badgeReaderRepository";
import { BadgeReaderDirection as Direction } from "../../../../domain/entities/badgeReaderDirection.enum";
import DeleteButton from "../../Common/table/DeleteButton";
import ActionButton from "../../Common/table/ActionButton";
import { BiUnlink } from "react-icons/bi";
import { Permission } from "../../Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

type BadgeReadersTableProps = {
  badgeReaders: BadgeReader[];
  badgeReadersIsFetching: boolean;
  badgeReadersFilters: BadgeReadersFilters;
  updateBadgeReadersFilters: (
    field: keyof BadgeReadersFilters,
    value: string | string[]
  ) => void;
  badgeReadersSorting: SortMeta;
  updateBadgeReadersSorting: (sort: SortMeta) => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  onBadgeReadersClick: (badgeReader: BadgeReader) => void;
  permissions: {
    delete: Permission;
    edit: Permission;
    show:Permission; };
  deleteBadgeReader: ({ badgeReaderId }) => void;
  isPhysical?: boolean;
  autosize?: boolean;
  unlinkBadgeReader: ({ badgeReaderId }) => void;
};

export enum HeartbeatValues {
  ACTIVE_NOW = "active_now",
  RECENTLY_ACTIVE = "recently_active",
  NOT_ACTIVE = "not_active",
}

const BadgeReadersTable = ({
  badgeReaders,
  badgeReadersIsFetching,
  badgeReadersFilters,
  updateBadgeReadersFilters,
  badgeReadersSorting,
  updateBadgeReadersSorting,
  hasNextPage,
  fetchNextPage,
  onBadgeReadersClick,
  unlinkBadgeReader,
  isPhysical,
  autosize, permissions, deleteBadgeReader,
}: BadgeReadersTableProps) => {
  const { t } = useTranslation("badgeReaders");
  const showStatus = useHasPermissions(permissions.show)

  const tableColumns: {
    field: keyof BadgeReadersFilters;
    type: "text" | "select";
    width: string;
    options?: Record<string, string>;
  }[] = [
    { field: "name", type: "text", width: "120px" },
    { field: "readerDirection", type: "select", width: "200px", options: Direction },
  ];

  if (isPhysical) {
    tableColumns.push({ field: "lastSync", type: "text", width: "110px" });
    tableColumns.splice(1, 0, { field: "serial", type: "text", width: "100px" });
  }
  if (!isPhysical) {
    tableColumns.splice(1, 0, { field: "userName", type: "text", width: "200px" });
  }
  if (showStatus) {
    tableColumns.splice(3, 0,{ field: "status", type: "select", width: "120px", options: BadgeReaderState });
  }

  const checkHeartBeat = (date: Date) => {
    if (!date) {
      return HeartbeatValues.NOT_ACTIVE;
    }
    const targetDate = new Date(date);

    const currentTimeMinus2Minutes = new Date();
    currentTimeMinus2Minutes.setMinutes(
      currentTimeMinus2Minutes.getMinutes() - 2
    );

    const currentTimeMinus10Minutes = new Date();
    currentTimeMinus10Minutes.setMinutes(
      currentTimeMinus10Minutes.getMinutes() - 10
    );

    if (targetDate < currentTimeMinus10Minutes) {
      return HeartbeatValues.NOT_ACTIVE;
    } else if (targetDate < currentTimeMinus2Minutes) {
      return HeartbeatValues.RECENTLY_ACTIVE;
    } else {
      return HeartbeatValues.ACTIVE_NOW;
    }
  };
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width="calc(100vw - 264px)"
      marginTop={3}
      position="relative"
      overflow="auto"
      id="table-container"
    >
      <InfiniteTable
        autosize={autosize}
        bottomThreshold={300}
        infiniteScroll={{
          dataLength: badgeReaders.length,
          hasNextPage,
          fetchNextPage,
        }}
        tableId="badge-readers-table"
        isLoading={badgeReadersIsFetching}
        emptyText={
          Object.keys(badgeReadersFilters).length > 0
            ? t("noBadgeReadersFound", { ns: "badgeReaders" })
            : t("noSiteBadgeReaders", { ns: "badgeReaders" })
        }
      >
        <Thead>
          <Tr>
            {tableColumns.map((column) => (
              <Th width={column.width} key={column.field}>
                <TableColumnHeader
                  text={t(`columns.${column.field}`)}
                  filter={
                    column.field !== "name" &&
                    column.field !== "serial" &&
                    column.field !== "lastSync" && {
                      component: (
                        <ColumnFilterComponent
                          type={column.type}
                          value={badgeReadersFilters[column.field]}
                          updateFilter={(value) =>
                            updateBadgeReadersFilters(
                              column.field,
                              value as string | string[]
                            )
                          }
                          selectOptions={column.options}
                          namespace="badgeReaders"
                        />
                      ),
                      isActive: !!(Array.isArray(
                        badgeReadersFilters[column.field]
                      )
                        ? badgeReadersFilters[column.field][1]
                        : badgeReadersFilters[column.field]),
                    }
                  }
                  sort={
                    column.field !== "lastSync" && {
                      handler: (direction) =>
                        updateBadgeReadersSorting({
                          field: column.field,
                          direction,
                        }),
                      direction:
                        badgeReadersSorting &&
                        badgeReadersSorting.field === column.field
                          ? badgeReadersSorting.direction
                          : null,
                    }
                  }
                />
              </Th>
            ))}
            <RenderIf permissions={[permissions.delete, permissions.edit]} check={PermissionCheck.Some}>
              <Th width={100} />
            </RenderIf>
          </Tr>
        </Thead>
        <Tbody>
          {badgeReaders.map((badgeReader) => (
            <Tr
              key={badgeReader?.id}
              onClick={() => onBadgeReadersClick(badgeReader)}
              sx={{ cursor: "pointer" }}
            >
              <Td width={120}>{badgeReader?.name} </Td>
              {!isPhysical && <Td width={200}>{badgeReader?.userName}</Td>}
              {isPhysical && <Td width={100}>{badgeReader?.serial}</Td>}
              <Td width={200}>
                <BadgeReaderDirection
                  direction={badgeReader?.direction}
                  releConfiguration={badgeReader?.releConfiguration}
                />
              </Td>
              <RenderIf permissions={[permissions.show]} check={PermissionCheck.All}>
                <Td width={120}>
                  <BadgeReaderStatus value={badgeReader?.status} />
                </Td>
              </RenderIf>
              {isPhysical && (
                <Td width={110}>
                  <Tooltip
                    label={
                      badgeReader?.lastPing
                        ? formatDateBasedOnLanguage(
                            badgeReader?.lastPing,
                            true
                          )
                        : "-"
                    }
                    aria-label="Preview"
                  >
                    <span>
                      {(() => {
                        switch (checkHeartBeat(badgeReader?.lastPing)) {
                          case HeartbeatValues.ACTIVE_NOW:
                              return (
                                <Icon as={CheckCircleIcon} color={"green"} />
                              );
                          case HeartbeatValues.RECENTLY_ACTIVE:
                            return <Icon as={WarningIcon} color={"orange"} />;
                          default:
                            return <Icon as={WarningTwoIcon} color={"red"} />;
                        }
                      })()}
                    </span>
                  </Tooltip>
                </Td>
              )}
                <Td width={100}>
                  <RenderIf permissions={[permissions.delete]} check={PermissionCheck.All}>
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteBadgeReader({ badgeReaderId: badgeReader?.id });
                      }}
                    />
                  </RenderIf>

                    {isPhysical &&
                      <Tooltip label={t("disassociates")}>
                        <span>
                          <RenderIf permissions={[permissions.edit]} check={PermissionCheck.All}>
                            <ActionButton
                              aria-label="disassociates"
                              icon={<BiUnlink />}
                              onClick={(e) => {
                                e.stopPropagation();
                                unlinkBadgeReader({
                                  badgeReaderId: badgeReader?.id,
                                });
                              }}
                            />
                          </RenderIf>
                        </span>
                      </Tooltip>}
                </Td>
            </Tr>
          ))}
        </Tbody>
      </InfiniteTable>
    </Flex>
  );
};

export default BadgeReadersTable;
