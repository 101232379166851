import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import DOMPurify from "dompurify";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiLink } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ResourceEvaluationState } from "../../../domain/entities/resourceEvaluationState.enum";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import { formatDateBasedOnLanguage } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import BaseModal from "../../components/Common/alerts/BaseModal";
import RenderIf, { useHasPermissions } from "../../components/Permissions/RenderIf";
import SelectBadgesTable from "../../components/Views/Badge/SelectBadgesTable";
import { Column } from "../../components/Views/Documents/GeneralDocumentsView";
import SupplierDocumentsTable, {
  SupplierDocumentsProps,
} from "../../components/Views/common/SupplierDocumentsTable";
import { useSupplierOrSiteFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import useSupplierResourceDetailViewModel from "../../hooks/Suppliers/useSupplierResourceDetailViewModel";
import SupplierResourceViewModel from "../../viewmodels/suppliers/SupplierResourceViewModel";
import { Alert } from "../Common/Alert";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import LoadingView from "../Common/LoadingView";
import ResourceSitesList from "../Resources/ResourceSitesList";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

type Props = {
  viewModel: SupplierResourceViewModel<
    | SiteWorkerParams
    | SiteMachineParams
    | SiteToolParams
    | SiteVehicleParams
    | SiteChemicalParams
  >;
  columns: string[];
  permissions: SupplierDocumentsProps["permissions"];
};

const SupplierResourceView: FC<Props> = ({
  viewModel,
  columns,
  permissions,
}) => {
  const { t } = useTranslation("supplier");
  const {
    supplierResource,
    isLoadingSupplierResource,
    evaluateSupplierResource,
    supplierResourceDocuments,
    refetchSupplierResourceDocuments,
    supplierResourcesHasNextPage,
    supplierResourcesFetchNextPage,

    resourceSites,
    isFetchingResourceSites,
		resourceSitesHasNextPage,
    resourceSitesFetchNextPage,
    filterResourceSites,
    updateFilterSupplierResourceSites,
    setSortResourceSites,
    sortResourceSites,
    supplierResourceEvaluations,
    getSupplierResourceEvaluations,
    supplierResourcesEvaluationHasNextPage,
    supplierResourcesEvaluationFetchNextPage,
    supplierResourceEvaluationsIsFetching,
    setEnableGetEvaluationsHistory,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,
    evaluateDocument,

    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,
    getDocumentEvaluations,
    documentEvaluations,
    documentEvaluationsFetching,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    documentInfoProps,
    createSupplierResourceDocument,
    updateSupplierResourceDocument,
    setEnableGetBadges,
    badgeIds,
    setBadgeIds,
    setBadgeId,
    tags,
    availableBadgesFilters,
    availableBadgesIsLoading,
    availableBadgesRefetch,
    availableBadgesResource,
    availableBadgesSort,
    linkBadgesToResource,
    linkBadgesToResourceIsLoading,
    setAvailableBadgesSort,
    setShowInfoSuccess,
    showInfoSuccess,
    unlinkBadgeResource,
    updateFilterAvailableBadge,
    unlinkBadgeResourceIsLoading,
    supplierResourceSites,
    setSortSupplierResourceSites,
    sortSupplierResourceSites,
    updateSupplierResourceSites,
    hasSites,
    setFilterResourceSites,
    supplierResourceSitesHasNextPage,
    supplierResourceSitesFetchNextPage,
    supplierResourceSitesIsFetching,
    supplierResourceSiteCount,
    setEnableGetResourceSites,
    updateFilterResourceSites,
    repropagateEvaluationDocument,
    setSuccessMsg,
    successMsg
  } = useSupplierResourceDetailViewModel(viewModel);

  const downloader = useSupplierOrSiteFilesDownloader(
    viewModel.supplierId,
    "company",
  );

  useEffect(() => {
    if (!linkBadgesToResourceIsLoading) {
      setLinkBadgeModal(false);
    }
  }, [linkBadgesToResourceIsLoading]);

  const [
    showConfirmEvaluateResourceModal,
    setShowConfirmEvaluateResourceModal,
  ] = useState<false | ResourceEvaluationState>(false);
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal,
  ] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [linkBadgeModal, setLinkBadgeModal] = useState(false);

  const canViewDocuments = useHasPermissions([permissions.viewDocuments])

  const columnsTable: Column[] = [
    { field: "name", filterType: "text" as const, label: t("name") },
    {
      field: "expiresAt",
      filterType: "date-range" as const,
      label: t("expireDate", { ns: "documents" }),
    },
    { field: "tags", filterType: "tags" as const, label: t("tags") },
  ];

  const getDetailValue = (column: string) => {
    if (["dateOfBirth", "dateOfEmployment"].includes(column))
      return formatDateBasedOnLanguage(supplierResource[column]);
    return supplierResource[column];
  };

  const getResourceName = () => {
    if (supplierResource && columns.includes("firstName")) {
      return `${supplierResource["firstName"]} ${supplierResource["lastName"]}`;
    } else if (supplierResource && columns.includes("name")) {
      return supplierResource["name"];
    } else {
      return "";
    }
  };

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("linkToBadges", { ns: "badges", resource: getResourceName() }),
  );
  const sanitizedHtmlContentUnlinkBadges = DOMPurify.sanitize(
    t("unlinkResourceFromBadge", { ns: "badges", resource: getResourceName() }),
  );

  const navigate = useNavigate();

  const goToSiteConnection = (siteId: string) => {
    navigate(`/sites/${siteId}/resources/${viewModel.type}s/${viewModel.resourceId}`)
  }

  useEffect(() => {
    if(!canViewDocuments) {
      setEnableGetResourceSites(true)
    }
  },[canViewDocuments])

  if (!supplierResource) {
    // SupplierResource data not yet available.
    return <LoadingView />;
  }
  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() =>
              navigate(
                `/suppliers/${viewModel.supplierId}/resources/${viewModel.type}s`,
              )
            }
          />
          <RenderIf permissions={permissions.linkBadge}  check={PermissionCheck.All}>
            <ActionBarItem
              icon={BiLink}
              description={t("linkToBadgesTooltip", {
                ns: "badges",
                resource: `${getResourceName()}`,
              })}
              onClick={() => {
                setEnableGetBadges(true);
                setLinkBadgeModal(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      {supplierResource && (
        <Box px={3} py={5}>
          <DetailsHeader
            title={t("details", { ns: "common" })}
            image={{ url: supplierResource.photo }}
            attributes={columns.map((column) => ({
              label: t(column, { ns: "siteResources" }),
              value: getDetailValue(column),
            }))}
            isLoaded={!isLoadingSupplierResource}
            shouldShowImage={true}
          />
          <Box
            mt={5}
            p={3}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <Tabs width="100%" height="100%" defaultIndex={0}>
              <TabList>
                {canViewDocuments &&
                  <Tab width="50%">{t("documents", { ns: "common" })}</Tab>
                }
                <Tab
                  width={canViewDocuments ? "50%" : '100%'}
                  onClick={() => setEnableGetResourceSites(true)}
                >
                  {t("sites", { ns: "sites" })}
                </Tab>
              </TabList>
              <TabPanels>
                {canViewDocuments &&
                  <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                    <SupplierDocumentsTable
                      tableColumns={columnsTable}
                      downloader={downloader}
                      documents={supplierResourceDocuments}
                      refetchDocument={refetchSupplierResourceDocuments}
                      documentsHasNextPage={supplierResourcesHasNextPage}
                      documentsFetchNextPage={supplierResourcesFetchNextPage}
                      evaluateDocument={evaluateDocument}
                      sortDocuments={sortDocuments}
                      setSortDocuments={updateSortDocuments}
                      filterDocuments={filterDocuments}
                      updateFilterDocuments={updateFilterDocuments}
                      updateSortDocuments={updateSortDocuments}
                      getDocumentEvaluations={getDocumentEvaluations}
                      documentEvaluations={documentEvaluations}
                      documentEvaluationsFetching={documentEvaluationsFetching}
                      filterDocumentEvaluations={filterDocumentEvaluations}
                      updateFilterDocumentEvaluations={
                        updateFilterDocumentEvaluations
                      }
                      sortDocumentEvaluations={sortDocumentEvaluations}
                      updateSortDocumentEvaluations={
                        updateSortDocumentEvaluations
                      }
                      {...documentInfoProps}
                      isCompanySupplier
                      createDocumentWithProperties={
                        createSupplierResourceDocument
                      }
                      updateDocument={updateSupplierResourceDocument}
                      repropagateEvaluationDocument={(documentId: string, siteIds: string[], selectAll: boolean) => 
                        repropagateEvaluationDocument({documentId, siteIds, selectAll})}
                      autosize
                      tags={tags}
                      permissions={permissions}
                      fromSupplierResource={true}
                      supplierResourceSites={supplierResourceSites}
                      hasSites={hasSites}
                      setSortResourceSites={setSortSupplierResourceSites}
                      sortResourceSites={sortSupplierResourceSites}
                      updateFilterResourceSites={updateFilterSupplierResourceSites}
                      filterResourceSites={filterResourceSites}
                      setFilterResourceSites={setFilterResourceSites}
                      supplierResourceSitesHasNextPage={supplierResourceSitesHasNextPage}
                      supplierResourceSitesFetchNextPage={supplierResourceSitesFetchNextPage}
                      supplierResourceSitesIsFetching={supplierResourceSitesIsFetching}
                      deltaWidth={170}
                    />
                  </TabPanel>
                }
                <TabPanel key={"site"} paddingLeft={0} paddingRight={0}>
                  <ResourceSitesList
                    sites={resourceSites}
                    sitesFilter={filterResourceSites}
                    updateSitesFilter={updateFilterResourceSites}
                    setSitesSort={setSortResourceSites}
                    sitesSort={sortResourceSites}
                    sitesLoading={isFetchingResourceSites}
                    getSitesHasNextPage={resourceSitesHasNextPage}
                    getSitesFetchNextPage={resourceSitesFetchNextPage}
                    showBadgeColumns
                    navigateAction={(siteId)=>goToSiteConnection(siteId)}
                    showUnlink={true}
                    unlinkBadgeAction={(badgeId) => {
                      setBadgeId(badgeId);
                      setShowDeleteAlert(true);
                    }}
                    tableId="supplier-resource-sites"
                    deltaWidth={170}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}

      {showConfirmEvaluateResourceModal && (
        <BaseModal
          title={t("confirmEvaluationForced", { ns: "siteResources" })}
          onClose={() => setShowConfirmEvaluateResourceModal(false)}
          onConfirm={() => {
            evaluateSupplierResource(
              showConfirmEvaluateResourceModal as ResourceEvaluationState,
            );
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t("showConfirmEvaluateResourceModal", {
                ns: "siteResources",
              }),
            }}
          />
        </BaseModal>
      )}

      {/* At the moment this component is not visible on the page
          but we leave it here for any changes in functionality */}
      {showResourceEvaluationHistoryModal && (
        <ResourceEvaluationHistoryModal
          onClose={() => {
            setEnableGetEvaluationsHistory(false);
            setShowResourceEvaluationHistoryModal(false);
          }}
          evaluations={supplierResourceEvaluations}
          getEvaluations={getSupplierResourceEvaluations}
          evaluationsHasNextPage={supplierResourcesEvaluationHasNextPage}
          evaluationsFetchNextPage={supplierResourcesEvaluationFetchNextPage}
          getEvaluationsIsLoading={supplierResourceEvaluationsIsFetching}
          filterResourceEvaluations={filterResourceEvaluations}
          updateFilterResourceEvaluations={updateFilterResourceEvaluations}
          sortResourceEvaluations={sortResourceEvaluations}
          updateSortResourceEvaluations={updateSortResourceEvaluations}
        />
      )}
      {linkBadgeModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkBadgeModal(false)}
          title={
            <Text
              fontWeight={"normal"}
              fontSize={20}
              dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
            />
          }
          footer={[
            <Button
              key="confirm"
              isDisabled={!badgeIds || badgeIds?.length === 0}
              colorScheme="blue"
              onClick={async () => {
                await linkBadgesToResource();
              }}
              isLoading={linkBadgesToResourceIsLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkBadgeModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadgesResource}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateFilterAvailableBadge}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            selectAllAvailable={false}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("badgeAssociatedToResource", { ns: "badges" })}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(false);
          }}
        />
      )}
        {successMsg && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(successMsg, { ns: "badges" })}
          variant="info"
          onClose={() => {
            setSuccessMsg(null);
          }}
        />
      )}
      <DeleteActionAlert
        mainTitle={t("warning", { ns: "common" })}
        title={
          <Text
            fontWeight={"normal"}
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContentUnlinkBadges,
            }}
          />
        }
        onConfirm={() => {
          unlinkBadgeResource(), setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        isLoading={unlinkBadgeResourceIsLoading}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </ContentLayout>
  );
};

export default SupplierResourceView;
