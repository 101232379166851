import React, { FC, useCallback, useEffect } from "react";
import { Button, Checkbox, Text, Tbody, Td, Th, Thead, Tr, Flex } from "@chakra-ui/react";
import InfiniteTable from "../table/InfiniteTable";
import TableColumnHeader from "../table/TableColumnHeader";
import ColumnFilterComponent, { ColumnFilterType } from "../table/ColumnFilterComponent";
import { Resource } from "../../../../domain/repositories/smartDownloadRepository";
import { SelectedResourcesByType } from "../../../hooks/SmartDownload/useSmartDownloadViewModel";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import { GetSupplierResourcesFilters } from "../../../hooks/Suppliers/useSupplierResourcesViewModel";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { useTranslation } from "react-i18next";
import { SelectionState } from "./SmartDownloadSelectSubContextDataByResourceType";
import { DocumentTypeFilter } from "../../../../infrastructure/adapters/documentTypeFilter";

const defaultFilters = {
  [ResourceType.WORKER]: {
    firstName: "",
    lastName: "",
    fiscalCode: "",
    jobTitle: "",
  },
  [ResourceType.VEHICLE]: {
    name: "",
    plate: "",
    model: "",
  },
  [ResourceType.MACHINE]: {
    name: "",
    plate: "",
    model: "",
  },
  [ResourceType.TOOL]: {
    name: "",
    serialNumber: "",
  },
  [ResourceType.CHEMICAL]: {
    name: "",
  },
};
const workerColumns = [
  {
    field: "firstName",
    label: "firstName",
    type: "text",
  },
  {
    field: "lastName",
    label: "lastName",
    type: "text",
  },
  {
    field: "fiscalCode",
    label: "fiscalCode",
    type: "text",
  },
  {
    field: "jobTitle",
    label: "jobTitle",
    type: "text",
  }
];
const machineAndVehicleColumns = [
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "plate",
    label: "plate",
    type: "text",
  },
  {
    field: "company",
    label: "company",
    type: "text",
  },
  {
    field: "model",
    label: "model",
    type: "text",
  }
];
const toolColumns = [
  {
    field: "name",
    label: "name",
    type: "text",
  },
  {
    field: "serial",
    label: "serial",
    type: "text",
  }
];
const chemicalColumns = [
  {
    field: "name",
    label: "name",
    type: "text",
  }
];
interface SmartDownloadSelectResourceSubContextTableProps {
  data: Resource[] | DocumentType[];
  count?: number;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isLoading?: boolean;
  filters?: GetSupplierResourcesFilters | DocumentTypeFilter;
  sort?: SortMeta;
  setSort?: (sort: SortMeta) => void;
  selectedDataResourcesByType?: SelectedResourcesByType;
  setSelectedDataResourcesByType?: (resources: SelectedResourcesByType) => void;
  resourceType: ResourceType;
  updateFilters: (column: string, value: string | string[] | [Date, Date]) => void;
  selectionState?: SelectionState,
  setSelectionState?: (state: (prevState: SelectionState) => SelectionState) => void;
  customColumns?: [{field: string,
    label: string,
    type: "text" | "tag"}]
}

const SmartDownloadSelectSubContextDataTable: FC<SmartDownloadSelectResourceSubContextTableProps> = ({
  data,
  count,
  hasNextPage,
  fetchNextPage,
  isLoading,
  filters,
  sort,
  setSort,
  resourceType,
  updateFilters,
  selectionState,
  setSelectionState,
  customColumns}) => {
  const { t } = useTranslation();
  const getFilters = useCallback(() => {
    return filters ?? defaultFilters[resourceType] ?? [];
  }, [resourceType, filters]);

  const resourceFilters = getFilters();
  const createColumns = (resourceType: ResourceType) => {
    switch (resourceType) {
      case ResourceType.WORKER:
        return workerColumns
      case ResourceType.VEHICLE:
      case ResourceType.MACHINE:
        return machineAndVehicleColumns
      case ResourceType.TOOL:
        return toolColumns
      case ResourceType.CHEMICAL:
        return chemicalColumns
      default:
        return [];
    }
  };
  const columns = customColumns ? customColumns : createColumns(resourceType);

  // Selection logic.
  const toggleItem = (resource: Resource) => {
    const isSelected = selectionState.selectedResources[resourceType].some(
      (r) => r.id === resource.id
    );

    setSelectionState((prevState) => {
      const updatedSelectedResources = {
        ...prevState.selectedResources,
        [resourceType]: isSelected
          ? prevState.selectedResources[resourceType].filter(
            (r) => r.id !== resource.id
          )
          : [...prevState.selectedResources[resourceType], resource],
      };

      const allSelected =
        updatedSelectedResources[resourceType].length === data.length;

      return {
        ...prevState,
        selectedResources: updatedSelectedResources,
        selectAllVisible: {
          ...prevState.selectAllVisible,
          [resourceType]: allSelected,
        },
      };
    });
  };

  const toggleSelectAll = (value: boolean) => {
    setSelectionState((prev) => ({
      ...prev,
      selectAllVisible: {
        ...prev.selectAllVisible,
        [resourceType]: value,
      },
      selectedResources: {
        ...prev.selectedResources,
        [resourceType]: value ? [...data] : [],
      },
    }));
  };

  const handleSelectionButton = () => {
    setSelectionState((prev) => {
      const isCurrentlySelected = prev.selectAllPaginated[resourceType];

      return {
        ...prev,
        selectAllPaginated: {
          ...prev.selectAllPaginated,
          [resourceType]: true,
        },
        selectedResources: {
          ...prev.selectedResources,
          [resourceType]: !isCurrentlySelected ? [...data] : [],
        },
      };
    });
  };

  useEffect(() => {
    if (data?.length > 0 && (selectionState.selectAllPaginated[resourceType] || selectionState.selectAllVisible[resourceType])) {
      const visibleResourceIds = data.map((resource) => resource.id);
  
      const allSelectedIds = [
        ...visibleResourceIds,
        ...(selectionState?.selectedResources[resourceType]?.map((r) => r.id) || []),
      ];
  
      const uniqueResourceIds = Array.from(new Set(allSelectedIds));
  
      setSelectionState((prevState) => ({
        ...prevState,
        selectedResources: {
          ...prevState.selectedResources,
          [resourceType]: uniqueResourceIds.map((id) => {
            const resource = (data as Resource[]).find((resource) => resource.id === id);
            return resource ? { ...resource } : null;
          }).filter(Boolean)
        },
      }));
    }
  }, [data, selectionState.selectAllPaginated[resourceType], selectionState.selectAllVisible[resourceType]]);
  


  return (
      <InfiniteTable
        autosize={true}
        maxHeight={"320px"}
        infiniteScroll={{
          dataLength: data?.length,
          hasNextPage: hasNextPage,
          fetchNextPage: fetchNextPage,
        }}
        tableId="supplier-resources-table"
        emptyText={t("noItems")}
        isLoading={isLoading}
      >
        <Thead>
          <Tr>
            <Th key={"selectAllCheckbox"} width={10}>
              <Checkbox
                borderColor={"gray.500"}
                isChecked={selectionState.selectAllVisible[resourceType]}
                onChange={() => toggleSelectAll(!selectionState.selectAllVisible[resourceType])}
              />
            </Th>
            {columns.map((column) => (
              <Th width={200} key={column.field}>
                <TableColumnHeader
                  text={column.label === "company" ? t(column.label, { ns: "common" }) : t(column.label, { ns: resourceType +'s' })}
                  filter={{
                    component: (
                      <ColumnFilterComponent
                        type={column.type as ColumnFilterType}
                        value={resourceFilters[column.field]}
                        updateFilter={(value) =>
                          updateFilters(column.field, value)
                        }
                      />
                    ),
                    isActive: !!(resourceFilters[column.field]?.length),
                  }}
                  sort={{
                    handler: (direction) =>
                      setSort({ field: column.field, direction }),
                    direction:
                      sort && sort.field === column.field
                        ? sort.direction
                        : null,
                  }}
                  isInModal
                />
              </Th>
            ))}
          </Tr>
        </Thead>

      <Tbody
        height="300px"
        style={selectionState.selectAllVisible[resourceType] ?
          { "tableLayout": "auto" } :
          { "tableLayout": "fixed" }}
      >
          {selectionState.selectAllVisible[resourceType] && (
            <Tr width="100%">
              <Th colSpan={columns.length+1} backgroundColor={"gray.100"}>
                <Text textAlign="center" mx="auto">
                  {!selectionState.selectAllPaginated[resourceType] &&
                    t("siteSelectedVisible", { ns: "sites" })}
                  {selectionState.selectAllPaginated[resourceType] &&
                    t("resourcesSelectedNotVisible", {
                      ns: "siteResources",
                      count: count,
                    })}
                  {hasNextPage && (
                    <Button
                      mt="10px"
                      ml="4px"
                      colorScheme="blue"
                      variant="link"
                      onClick={() => handleSelectionButton()}
                    >
                      {t(
                        selectionState.selectAllPaginated[resourceType]
                          ? "clearSelection"
                          : "sitesSelectAll",
                        { ns: "sites" },
                      )}
                    </Button>
                  )}
                </Text>
              </Th>
            </Tr>
          )}
          {data?.map((itemRow: any) => (
            <Tr key={itemRow?.id}>
              <Td width={10} onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  borderColor="gray.500"
                  isChecked={selectionState.selectedResources[resourceType]?.some((r) => r.id === itemRow?.id)}
                  onChange={(e) => {
                    toggleItem(itemRow);
                    e.stopPropagation();
                  }}
                />
              </Td>
              {columns.map((column) =>  <Td width={200} key={column.field}>{itemRow && itemRow[column.field]}</Td>)}
            </Tr>
          ))}
        </Tbody>
      </InfiniteTable>
  );
};

export default SmartDownloadSelectSubContextDataTable;
