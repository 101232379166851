import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { LuPackagePlus } from "react-icons/lu";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import LoadingView from "../../Common/LoadingView";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import Vehicle from "../../../../domain/entities/vehicle";
import ContentLayout from "../../../layout/ContentLayout";
import { useNavigate, useParams } from "react-router-dom";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, { ResourceDocumentProps } from "../ResourceDocumentsView";
import RenderIf, {useHasPermissions} from "../../../components/Permissions/RenderIf";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import useVehicleViewModel from "../../../hooks/Resources/Vehicles/useVehicleViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import useVehicleDocumentsViewModel from "../../../hooks/Resources/Vehicles/useVehicleDocumentsViewModel";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import DOMPurify from "dompurify";
import SelectBadgesTable from "../../../components/Views/Badge/SelectBadgesTable";
import { BiLink } from "react-icons/bi";
import { Alert } from "../../Common/Alert";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import ResourceConversionButton from "../ResourceConversionButton";
import ResourceSitesList from "../ResourceSitesList";
import {ConversionType} from "../../../hooks/Resources/useResourceConversionHook";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import { LicenseType } from "../../../../domain/interactors/auth/args";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    editRecord: Permission
    showTypology: Permission
    editTypology: Permission
    manageDocumentWithAi: Permission
    linkBadge: Permission
    showResourceSites: Permission
    convertToMachine: Permission
  };
}

const VehicleDetailView: FC<Props> = ({ permissions }) => {
  const { id } = useParams();
  const hook = useVehicleViewModel(id as string)
  return hook.loading ? <LoadingView /> : <VehicleDetailViewMain permissions={permissions} hook={hook} />
}

const VehicleDetailViewMain: FC<Props & {hook: ReturnType<typeof useVehicleViewModel>}> = ({ permissions, hook }) => {
  const { id } = useParams();
  const { t } = useTranslation("vehicles");
  const viewDocument = useHasPermissions(permissions.documents.view)
  const viewSites = useHasPermissions(permissions.showResourceSites)
  const {
    vehicle,
    isFetching,
    updateImage,
    updateVehicle,

    resourceDocumentSites,
    isFetchingResourceDocumentSites,
    resourceDocumentSitesHasNextPage,
    resourceDocumentSitesFetchNextPage,
    updateFilterResourceDocumentSites,
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,

    resourceSites,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    hasSites,
    setEnableGetVehicleCollection,
    siteCollection,
    siteCollectionIsLoading,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection,
    siteCollectionHasNextPage,
    siteCollectionFetchNextPage,
    refetchSiteCollection,
    linkResourceToSites,
    isLinking,
    badgeIds,
    setBadgeIds,
    setBadgeId,
    selectAll,
    setSelectAll,
    availableBadgesRefetch,
    availableBadgesFilters,
    availableBadgesIsLoading,
    availableBadgesResource,
    availableBadgesSort,
    linkBadgesToResource,
    unlinkBadgeResourceIsLoading,
    linkBadgesToResourceIsLoading,
    setAvailableBadgesSort,
    setShowInfoSuccess,
    showInfoSuccess,
    unlinkBadgeResource,
    updateFilterAvailableBadge,
    conversionProps,
    setFilterResourceSites,
    resourceLinkableSitesCount,
    customFields,
  } = hook
  const { company } = useAuth();
  const documentsHook = useVehicleDocumentsViewModel(id);
  const downloader = useDocumentFilesDownloader();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const [linkBadgeModal, setLinkBadgeModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [copyLastEvaluation, setCopyLastEvaluation] = useState(false)
  const navigate = useNavigate();
  const showCopyLastEvaluation = useHasPermissions(Permission.Resources_EvalVehicleDocs)

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", {
      ns: "sites",
      resource: `${vehicle?.name}`,
    })
  );
  const sanitizedHtmlContentLinkBadges = DOMPurify.sanitize(
    t("linkToBadges", {
      ns: "badges",
      resource: `${vehicle?.name}`,
    })
  );
  const sanitizedHtmlContentUnlinkBadges = DOMPurify.sanitize(
    t("unlinkResourceFromBadge", {
      ns: "badges",
      resource: `${vehicle?.name}`,
    })
  );

  const goToVehiclesList = () => {
    navigate(`/resources/vehicles`);
  };
  const goToSiteConnection = (siteId: string) => {
    navigate(`/sites/${siteId}/resources/vehicles/${id}`)
  }
  const onSubmit: SubmitHandler<Vehicle> = (data) => {
    if (uploadedImageFile) {
      updateImage(
        { ...vehicle, ...data },
        uploadedImageFile
      );
    } else {
      updateVehicle({
        ...vehicle,
        ...data
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Vehicle>({ mode: "all", defaultValues: vehicle });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!linkBadgesToResourceIsLoading) {
      setLinkBadgeModal(false);
    }
  }, [linkBadgesToResourceIsLoading]);

  useEffect(() => {
      documentsHook?.communicationProps?.refetchResourceDocumentComments();
  }, []);

  const refetchCommentsAndNotes = () => {
      documentsHook.communicationProps.refetchMissingDocuments();
      documentsHook.communicationProps.refetchResourceDocumentComments();
      documentsHook.communicationProps.refetchResourceDocumentNotes();
      documentsHook.communicationProps.refetchNotificationStatus();
  };

  const convertPermissions = {
    machine: useHasPermissions(permissions.convertToMachine),
  }
  const twinTypes = conversionProps.twinTypes.map(twinType => convertPermissions[twinType] && twinType)
    .filter(tt => tt) as ConversionType[]

  if (!vehicle) {
    return <LoadingView />;
  }

  const handleLinkToSites = async () => {
    await linkResourceToSites(selectAll ? [] : selectedSiteIds, copyLastEvaluation, selectAll);
    setFilterSiteCollection(null);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToVehiclesList}
          />

          <RenderIf permissions={permissions.editRecord} check={PermissionCheck.All}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat} check={PermissionCheck.All}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
         {company?.type !== LicenseType.Smart &&
           <RenderIf permissions={permissions.showResourceSites} check={PermissionCheck.All}>
             <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={LuPackagePlus }
              description={t("addResourceToSitesTooltip", {
                ns: "sites",
                resource: `${vehicle.name}`,
              })}
              onClick={() => {
                setEnableGetVehicleCollection(true);
                setShowSiteCollection(true);
                refetchSiteCollection();
              }}
            />
           </RenderIf>
         }
         {company?.type === LicenseType.Master && <RenderIf permissions={permissions.linkBadge}>
            <ActionBarItem
              icon={BiLink}
              description={t("linkToBadgesTooltip", {
                ns: "badges",
                resource: `${vehicle.name}`
              })}
              onClick={async () => {
                await availableBadgesRefetch();
                setLinkBadgeModal(true);
              }}
            />
          </RenderIf>}
          {twinTypes.length > 0 && (
            <ResourceConversionButton
              {...conversionProps}
              twinTypes={twinTypes}
            />
          )}
        </ActionBar>
      }
    >
      {vehicle && (
        <Box width={"95%"} px={3} py={5} marginLeft={7}>
          <Box textStyle="h1" mb={2}>
            {vehicle?.name} {vehicle?.model}
          </Box>
          <Box
            pb={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width={"100%"}
            flex={"1 1 auto"}
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isFetching}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : vehicle.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="plate"
                    name="plate"
                    label={t("plate")}
                    rules={requiredRule}
                  />,
                  <FormTextField key="model" name="model" label={t("model")} />,
                ]}
                customFields={customFields.data}
              />
            </FormProvider>
          </Box>
          <RenderIf permissions={[permissions.documents.view, permissions.showResourceSites]} check={PermissionCheck.Some}>
            <Box
              padding={5}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              mt={5}
            >
              <Tabs height="100%" defaultIndex={0} marginTop={4} isFitted>
                <TabList>
                  {viewDocument && <Tab>{t("documents", { ns: "common" })}</Tab>}
                  {company?.type !== LicenseType.Smart && viewSites && <Tab>{t("sites", { ns: "sites" })}</Tab>}
                </TabList>
                <TabPanels>
                  {viewDocument &&
                    <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                      <ResourceDocumentsView
                        category={DocumentTypeCategory.VEHICLE}
                        documentsHook={documentsHook}
                        resourcePath="/resources/workers"
                        showAddDocumentType={showAddDocumentType}
                        setShowAddDocumentType={setShowAddDocumentType}
                        showManageTaskWithAI={showManageTaskWithAI}
                        setShowManageTaskWithAI={setShowManageTaskWithAI}
                        downloader={downloader}
                        resourceSites={resourceDocumentSites}
                        hasSites={hasSites}
                        isFetchingSites={isFetchingResourceDocumentSites}
                        hasNextPage={resourceDocumentSitesHasNextPage}
                        fetchNextPage={() => resourceDocumentSitesFetchNextPage()}
                        siteCount={totalResourceDocumentSiteCount}
                        filterResourceSites={filterResourceDocumentSites}
                        updateFilterResourceSites={updateFilterResourceDocumentSites}
                        setSortResourceSites={setSortResourceDocumentSites}
                        sortResourceSites={sortResourceDocumentSites}
                        permissions={permissions}
                        resourceId={vehicle.id}
                        setFilterResourceSites={setFilterResourceSites}
                      />
                    </TabPanel>
                  }
                  {company?.type !== LicenseType.Smart && viewSites &&
                    <TabPanel key={"site"} paddingLeft={0} paddingRight={0}>
                      <ResourceSitesList
                        sites={resourceSites}
                        sitesFilter={filterResourceSites}
                        updateSitesFilter={updateFilterResourceSites}
                        setSitesSort={setSortResourceSites}
                        sitesSort={sortResourceSites}
                        sitesLoading={isFetchingSites}
                        getSitesHasNextPage={hasNextPage}
                        getSitesFetchNextPage={fetchNextPage}
                        showBadgeColumns
                        navigateAction={(siteId)=> goToSiteConnection(siteId)}
                        showUnlink={true}
                        unlinkBadgeAction={(badgeId) => {
                          setBadgeId(badgeId);
                          setShowDeleteAlert(true);
                        }}
                      />
                    </TabPanel>
                  }
                </TabPanels>
              </Tabs>
            </Box>
          </RenderIf>
        </Box>
      )}
      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => {
                setFilterSiteCollection(null);
                setShowSiteCollection(false);
              }}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectSitesView
            autosize
            subtitle={sanitizedHtmlContent}
            showCopyLastEvaluation={showCopyLastEvaluation}
            setCopyLastEvaluation={setCopyLastEvaluation}
            copyLastEvaluation={copyLastEvaluation}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            isFetchingSites={siteCollectionIsLoading}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            siteCount={resourceLinkableSitesCount}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {linkBadgeModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkBadgeModal(false)}
          title={
            <Text
              fontWeight={"normal"}
              fontSize={20}
              dangerouslySetInnerHTML={{
                __html: sanitizedHtmlContentLinkBadges,
              }}
            />
          }
          footer={[
            <Button
              key="confirm"
              isDisabled={!badgeIds || badgeIds?.length === 0}
              colorScheme="blue"
              onClick={async () => {
                await linkBadgesToResource();
              }}
              isLoading={linkBadgesToResourceIsLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkBadgeModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadgesResource}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateFilterAvailableBadge}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            selectAllAvailable={false}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showInfoSuccess, { ns: showInfoSuccess === 'resourceAssociatedToSite' ? "siteResources" : "badges"})}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(null);
          }}
        />
      )}
      <DeleteActionAlert
        mainTitle={t("warning", { ns: "common" })}
        title={
          <Text
            fontWeight={"normal"}
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContentUnlinkBadges,
            }}
          />
        }
        onConfirm={() => {
          unlinkBadgeResource(), setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        isLoading={unlinkBadgeResourceIsLoading}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </ContentLayout>
  );
};
export default VehicleDetailView;
